import H1 from 'components/Layout/H1';
import MainPicture from 'components/Layout/MainPicture';
import main from 'images/static/trip/boat.png';
import boatIcon from 'images/static/trip/boat_icon.png';
import boat from 'images/static/trip/by_boat.png';
import plane from 'images/static/trip/by_plane.png';
import power from 'images/static/trip/current.png';
import money from 'images/static/trip/money.png';
import phone from 'images/static/trip/phone.png';
import planeIcon from 'images/static/trip/plane_icon.png';
import supply from 'images/static/trip/supply.png';
import React, { ReactElement } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { HelpText, Spacer } from './styled';

const Trip = (): ReactElement => {
    const { t, i18n } = useTranslation('static');

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}${
                        i18n.language === 'fr' ? '/saint-barth/voyager' : '/saint-barth/trip'
                    }`}
                />
                <meta property="og:site_name" content={t('static:trip.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:trip.meta.og.title')} />
                <meta property="og:description" content={t('static:trip.meta.og.description')} />
                <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}images/static/trip/boat.png`} />
                <meta property="twitter:title" content={t('static:trip.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:trip.meta.twitter.description')} />
                <meta
                    property="twitter:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/trip/boat.png`}
                />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:trip.meta.title')} />
                <title>{t('static:trip.meta.title')}</title>
                <meta property="description" content={t('static:trip.meta.description')} />
            </Helmet>
            <Container fluid>
                <div>
                    <MainPicture src={main} alt="boat" />
                </div>
                <Container>
                    <Row>
                        <Col xs={12} className="text-center">
                            <H1>
                                <Trans t={t}>static:trip.title</Trans>
                            </H1>
                            <h5 className="fw-light">
                                <Trans t={t}>static:trip.subtitle</Trans>
                            </h5>
                        </Col>
                    </Row>
                    <div className="flex-grow-1">
                        <Container>
                            <Row className="py-5">
                                <Col xs={12} xl={6}>
                                    <Spacer />
                                    <h4 className="fw-light text-uppercase text-center p-3">
                                        <Image src={planeIcon} alt="plane" className="me-3" />
                                        <Trans t={t}>static:trip.by_plane</Trans>
                                    </h4>
                                    <Image src={plane} fluid className="mx-auto d-block w-100" alt="by plane" />
                                    <div className="mt-4">
                                        <p>
                                            <Trans t={t}>static:trip.plane.paragraph1</Trans>
                                        </p>
                                        <p>
                                            <Trans t={t}>static:trip.plane.paragraph2</Trans>
                                        </p>
                                        <p>
                                            <Trans t={t}>static:trip.plane.paragraph3</Trans>
                                        </p>
                                        <p>
                                            <Trans t={t}>static:trip.plane.paragraph4</Trans>
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <Spacer />
                                    <h4 className="fw-light text-uppercase text-center p-3">
                                        <Image src={boatIcon} alt="boat" className="me-3" />
                                        <Trans t={t}>static:trip.by_boat</Trans>
                                    </h4>
                                    <Image src={boat} fluid className="mx-auto d-block w-100" />
                                    <div className="mt-4">
                                        <p>
                                            <Trans t={t}>static:trip.boat.paragraph1</Trans>
                                        </p>
                                        <p>
                                            <Trans t={t}>static:trip.boat.paragraph2</Trans>
                                        </p>
                                        <p>
                                            <Trans t={t}>static:trip.boat.paragraph3</Trans>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <HelpText>
                                    <Spacer />
                                    <p>
                                        <Trans t={t} i18nKey="static:trip.help">
                                            <a
                                                href="mailto:reservations@idealstbarth.com"
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                            <a href="tel:+ 590 (0) 690 47 02 52" />
                                        </Trans>
                                    </p>
                                    <Spacer />
                                </HelpText>
                            </Row>
                            <Row>
                                <Col xs={12} className="text-center mb-5">
                                    <H1 as="h2">
                                        <Trans t={t}>static:trip.local_advices.title</Trans>
                                    </H1>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <div className="d-inline-flex">
                                        <Image src={money} alt="money" fluid className="d-block me-3" />
                                        <strong className="text-uppercase mt-1">
                                            <Trans t={t}>static:trip.local_advices.advices.money.title</Trans>
                                        </strong>
                                    </div>
                                    <p className="mt-1">
                                        <Trans t={t}>static:trip.local_advices.advices.money.content</Trans>
                                    </p>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <div className="d-inline-flex">
                                        <Image src={power} alt="power" fluid className="d-block me-3" />
                                        <strong className="text-uppercase mt-1">
                                            <Trans t={t}>static:trip.local_advices.advices.power.title</Trans>
                                        </strong>
                                    </div>
                                    <p className="mt-1">
                                        <Trans t={t}>static:trip.local_advices.advices.power.content</Trans>
                                    </p>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <div className="d-inline-flex">
                                        <Image src={phone} alt="phone" fluid className="d-block me-3" />
                                        <strong className="text-uppercase mt-1">
                                            <Trans t={t}>static:trip.local_advices.advices.phone.title</Trans>
                                        </strong>
                                    </div>
                                    <p className="mt-1">
                                        <Trans t={t}>static:trip.local_advices.advices.phone.content</Trans>
                                    </p>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <div className="d-inline-flex">
                                        <Image src={supply} alt="supply" fluid className="d-block me-3" />
                                        <strong className="text-uppercase mt-1">
                                            <Trans t={t}>static:trip.local_advices.advices.supply.title</Trans>
                                        </strong>
                                    </div>
                                    <p className="mt-1">
                                        <Trans t={t}>static:trip.local_advices.advices.supply.content</Trans>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Container>
        </React.Fragment>
    );
};

export default Trip;
