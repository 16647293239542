import styled from 'styled-components';
import { Col } from 'react-bootstrap';

const Spacer = styled.hr.attrs(() => ({
    size: '1',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.golden['100']};
    height: 2px;
    opacity: 1;
`;

const HelpText = styled(Col).attrs(() => ({
    xs: 12,
    className: 'text-center text-uppercase',
}))`
    & > p {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        font-size: 12px;
        font-weight: 500;
    }
`;

export { HelpText, Spacer };
